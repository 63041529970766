// 
// utilisation.scss
//

// utilisation input page


.header-select {
    padding: 5px;
    //max-height: 20px !important;
    max-width: 150px;
}

.utilisation-input {
    background-color: $bg-utilisation-input;
    color: $gray-700;
    //padding: 5px 0 5px 0;
    padding: 5px;
    display: block;

    .form-group {
        margin-bottom: 0;
    }

    input {
        height: 30px;
        width: 120px;
    }

    select {
        height: 30px;
        width: 120px;
    }
}

.inline-select {
    padding: 0;
    max-height: 30px !important;
    max-width: 130px;
}

.utilisation-input-spacer {
    background-color: transparent;
    height: 5px;
}


.utilisation-col {
    width: 400px;
    //min-width: 400px;
    //max-width: 400px;
    //white-space: nowrap;
}


.asset-col {
    width: 300px;
    //padding-top: 35px;
    //max-width: 200px;
}

.asset-col-img {
    padding-bottom: 10px;
}

.cumulativeDate-col {
    width: 180px;
    //padding-bottom: 70px !important;
    //max-width: 200px;
}

.hours-col {
    width: 130px;
    max-width: 300px;
    white-space: nowrap;
}

.cycles-col {
    width: 130px;
    max-width: 130px;
}

.icon-col-very-first-row {
    background-color: transparent;
    color: $gray-700;
    //width: 40px;
    padding: 27px 0 0 0;
}

.icon-col-first-row {
    background-color: transparent;
    color: $gray-700;
    //width: 40px;
    padding: 27px 0 0 0;
}

.icon-col-second-row {
    background-color: transparent;
    color: $gray-700;
    //width: 40px;
    //max-width: 40px;
    padding: 27px 0 0 0;
}

.utilisation-row {
    // format rows of all react bootstrap tables 
    td {
        //vertical-align: top !important;
        cursor: auto !important;
    }
}

.footer-col {
    padding-left: 0 !important;
}

.utilisation-input-header {
    padding-left: 0px !important;
}


.img-aircraft {
    max-height: 50px;
    max-width: 200px;
}

.img-engine {
    max-height: 50px;
    max-width: 200px;
}

.linkCursor {
    cursor: pointer;

    input, label {
        cursor: pointer;
    }
}

.xtra-small-text {
    font-size: 0.6rem;
    //font-size: 10px;
}
// 
// buttons.scss
//

.btn {
    .mdi {
        &:before {
            line-height: initial;
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}

// Button Shadows
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-shadow($value);
    }
}

.unsubscribe-button {
    background-color: $bg-leftbar-dark;
    padding-left: 30px;
    padding-right: 30px;
}
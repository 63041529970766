//
// react pro sidebar - our own customer css to be applied to the component.
//

$menu-item-higlight-color: #fff;
$submenu-title-background-color: #3b73b9;
$submenu-title-color: #fff;

.ps-menu-button .ps-active {
    color: $menu-item-higlight-color !important;
}

.ps-menu-label .ps-active {
    color: $menu-item-higlight-color !important;
}

.menu-item-span {
    display: flex;
    align-items: center
}

.ps-submenu-root .ps-menu-label,
.menu-item-label-text {
    margin-left: 0.6em;
}

.ps-collapsed {
    width: 70px !important;
    min-width: 70px !important;
}

.ps-collapsed .ps-submenu-expand-icon {
    display: none;
}

.menu-header-text {
    background-color: $submenu-title-background-color;
    color: $submenu-title-color;
    padding-left: 0.5em;
    padding-top: 0.3em;
    height: 40px
}

.ps-menu-button:hover {
    color: $menu-item-higlight-color !important;
}

.ps-menu-button:hover .ps-menu-label {
    color: $menu-item-higlight-color !important;
}
﻿div .modal-95w {
    max-width: 95%;
}

.technicalProjectDetails .card-header {
    border-bottom: 0px;
}

.technicalProjectDetails .card-body {
    padding-top:0rem;
}

.projectTypeSelect {
    width: 60%;
    float: right;
}

.projectTypeSelect .custom-select {
    width: 60%;
}

.tpManagerColumn {
    width: 150px;
}

.tpDescriptionColumn {
    width: 320px;
}
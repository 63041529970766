﻿//
// Bids
//

.bidAmount {
    width: 140px;
    height: 38px;
}

.totalBidAmount {
    width: 145px;
    height: 38px;
    padding-right: 20px;
}

.bidComment {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    outline: none !important;
}


.bidDocumentsUpload .dropzone {
    min-height: 110px;
}

.bidDocumentsUpload .dropzone .dz-message {
    padding: 10px;
}

.bidTypeOptions {
    display: inline;
    padding: 0.45rem 0.9rem;
    float: right;
    border-radius: 0.25rem;
    width: 35%;
    text-align: right;
}

.bidTypeOptions .selectLabel {
    margin-right: 15px;
    margin-bottom: 0px;
    font-weight: bold;
}

.bidTypeOptions .custom-control-inline {
    margin-right: 0.5rem;
}

.bidError{
    margin-top: 20px;
}

th div.invalid-feedback {
    font-weight: normal;
}
﻿//
// Asset For Sale
//
.checkingSpinner {
    margin: auto;
    display: block;
}

.listingDetails .card-header {
    border-bottom: 0px;
}

.listingDetails .card-body {
    padding-top: 0rem;
}

.viewDocs {
    padding: 0;
}

.tableAssetRow td {
    vertical-align: middle;
}

.tableAssetRow img {
    width: 200px;
}

.commentsTable {
    margin-top: 10px;
}

.commentsTable td {
    border-top: 0px;
    padding: 0;
}

.commentsTable .imageColumn {
    width: 55px;
    line-height: 0.4;
}

.commentRow {
    margin-left: 35px;
    margin-bottom: 10px;
}

.commentHeader h5 {
    float:left;
    margin-right: 10px;
    padding-top:4px;
}

.commentHeader .text-muted {
    vertical-align: bottom;
}

.modal-body .card {
    margin-bottom: 0px;
}

.assetImageCol {
    padding-top: 0;
}

.includeBidsCol {
    width: 12rem;
}

.assetBidsCol {
    width: 10rem;
}

.assetDocsCol button {
    padding-left: 0px;
}

.optOutColumn {
    text-align: right;
}

.assetDocsModal .modal-header {
    border-bottom: 0px;
}

.assetDocsModal .documentTitle{
    width:40%;
}

.assetDocsModal .documentDate {
    width: 10%;
}

.assetDocsModal .documentSize {
    width: 10%;
    text-align:right;
}

.assetDocsModal .downloadIcon-col {
    width: 5%;
}

.engineImage {
    height: 80px;
}

.placeBid {
    margin-top: 35px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.modalProcessingMessage {
    margin: auto;
    display: block;
}

.modalProcessingMessage .checkingSpinner {
    margin-top: 10px;
}

.removeBidFile:hover {
    cursor: pointer;
}

#file-previews {
    margin-bottom: 10px;
}

#confirmBidModal .close {
    display: none;
}

#getAirvaultLinkModal .alert .close {
    display: none;
}

.bidDetailsNoSelect {
    font-style: italic;
}

#confirmBidDetails p {
    margin-bottom: 5px;
}

#enterPortfoltioBidAmountLabel {
    float:right;
    margin-top: 8px;
}

.alert .close {
    display:none;
}

#bidTypeChangeModal .close{
    display:none;
}

.ndaSelectLaw {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1rem;
}

.ndaText {
    margin-bottom: 20px;
}

.ndaSchedule {
    margin:auto;
    width: 60%;
}

.ndaSchedule h5 {
    text-align: center;
}

.ndaAcceptanceNote {
    margin-top: 20px;
}

.a4sTermsAndConditionsButtons {
    margin-top: 15px;
    margin-bottom: 15px;
}

.listingDetailsAcceptedNDANote{
    margin-top: 10px;
}

div[id='assetsTable'] table tfoot th {
    vertical-align: middle;
}

.componentsTable table {
    margin-bottom: 0;
}

.asset-table-header {
    font-size: 1.125rem;
    font-weight: 700;
}
// 
// leaseaccount.scss
//

// lease account pages


.numberColumn {
    text-align: right;
}


.labelColumn {
    font-weight: bold;
    //width: 100px;
    //max-width: 100px;
}

.fundBalancesTotals {
    float: right;
    display: inline;
    padding-right: 0.95rem;
    //min-width: 700px;
}